<template>
  <div>
    <ul class="qc-top">
      <li>
        <ave-select :options="searchOptions1" v-model="searchValue1" />
      </li>
      <li class="li-left-small">
        <ave-select :options="searchOptions2" v-model="searchValue2" />
      </li>
      <li class="li-left-small">
        <button class="li-btn">
          待关闭
        </button>
      </li>
      <li class="li-left-small">
        <button class="li-btn">
          已关闭
        </button>
      </li>
      <li class="li-right-search">
        <ave-search v-model="searchValue" style="width:360px" @search="initData" placeholder="any key to search" />
      </li>
    </ul>
    <div style="clear:both;" />
    <div>
      <table class="table">
        <tbody>
          <template v-for="(item,idx) in data">
            <tr :key="'tr1' + idx">
              <td colspan="5">
                <span>{{ item.createTime === undefined ? "" : item.createTime.substring(0,10) }}</span>
                <span class="dialog-btn-middle" />
                <span>确认单号:{{ item.qualityNo }}</span>
                <span class="dialog-btn-middle" />
                <span>订单号:{{ item.order.orderNo }}</span>
                <span class="dialog-btn-middle" />
                <span>供应商:{{ supplier[item.order.supplierId] }}</span>
              </td>
            </tr>
            <tr :key="'tr2' + idx" class="tr-main">
              <td width="25%">
                <ul class="orderlist-ul">
                  <li>
                    <span class="order-detail-title">翻译内容</span>
                    <span>{{ item.order.applicationName }}</span>
                  </li>
                  <li>
                    <span class="order-detail-title">源语言:</span>
                    <span>{{ languageMap[item.order.sourceLanguage] ?
                      languageMap[item.order.sourceLanguage].languageNameCh : "" }}</span>
                    <span class="dialog-btn-middle" />
                    <span class="order-detail-title">目标语言:</span>
                    <span v-for="(lang,tindex) in item.order.targetLangList" :key="tindex">
                      {{ languageMap[lang.languageId] ? languageMap[lang.languageId].languageNameCh : "" }}
                    </span>
                  </li>
                </ul>
              </td>
              <td width="10%">
                <span class="order-detail-title">总价(含税)</span>
                <span>{{ item.order.cost }}</span>
                <span>RMB</span>
              </td>
              <td width="20%">
                <span>供应商回复状态:</span>
                <span style="color:#5762ff;">
                  {{ item.isConfirm === "yes" ? "已确认 " + (item.isModify === "yes" ? "已修改" : "不修改") : "未反馈" }}</span>
              </td>
              <td width="10%">
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">
                    <span>问题说明:{{ item.remarks }}</span>
                    <span>附件:{{ item.fileName ? item.fileName : "none" }}</span>
                  </div>
                  <span class="qc-item-info">问题说明</span>
                </el-tooltip>
              </td>
              <td width="10%">
                <div v-if="item.huaweiStatus">
                  <span>华为:</span>
                  <span style="color:#5762ff;">
                    {{ item.huaweiStatus === 'yes' ? "同意" : "不同意" }}</span>
                </div>
                <div v-if="item.isConfirm === 'yes' && !item.huaweiStatus">
                  <table>
                    <tbody>
                      <tr>
                        <td>华为:</td>
                        <td>
                          <label class="radio">
                            <input type="radio"
                                   :name="item.id"
                                   value="1"
                                   v-model="qcConfirmMap[item.id]">
                            <span>同意</span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td>
                          <label class="radio">
                            <input type="radio"
                                   :name="item.id"
                                   value="0"
                                   v-model="qcConfirmMap[item.id]">
                            <span>不同意</span>
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="item.isDelayed === true">
                  <span class="red">!供应商超期未确认</span>
                  <div>
                    <span v-if="item.notified !== 'yes'" class="qc-item-info">提醒翻译供应商</span>
                    <span v-else style="color:#5762ff;">已提醒</span>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <tr v-if="data.length < 1" class="tr-main">
            <td colspan="5" class="orderlist-tr-nodata">
              --- no data ---
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="orderlist-bottom" v-if="data.length > 0">
      <span style="width:68%;display:inline-block;" />
      <ave-pager :pager="pager" @change="onPage($event)" :show-page-size="false" />
    </div>
    <div>
      <ave-toasts :msgs="toasts" :life="3000" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Qualitytrace',
  data() {
    return {
      searchOptions1: [
        { name: '全部', value: '0' },
        { name: '需今天内完成确认', value: '1' },
        { name: '需2内完成确认', value: '2' },
        { name: '需3内完成确认', value: '3' },
        { name: '需7内完成确认', value: '7' }
      ],
      searchOptions2: [
        { name: '全部', value: '0' },
        { name: 'SP1', value: '1' },
        { name: 'SP2', value: '2' },
        { name: 'SP3', value: '3' },
        { name: 'SP4', value: '7' }
      ],
      searchValue1: {},
      searchValue2: {},
      data: [],
      toasts: [],
      searchValue: '',
      pager: {
        total: 23,
        index: 0,
        pageSize: 10
      },
      languageMap: {}, // 所有的语言列表
      supplier: {}, // 所有供应商的id-name键值对
      status: {
        'init': '待确认',
        'nopay': '待支付',
        'pay': '待处理',
        'translate': '处理中',
        'appraise': '待评价',
        'end': '已完成'
      },
      payStatus: {
        'init': '未支付',
        'nopay': '未支付',
        'pay': '已支付',
        'translate': '已支付',
        'appraise': '已支付',
        'end': '已支付'
      },
      qcConfirmMap: {}// 华为确认情况
    };
  },
  mounted() {
    this.getLanguageList();
    this.searchValue1 = this.searchOptions1[0];
    this.searchValue2 = this.searchOptions2[0];
    this.getSupplier();
  },
  methods: {
    // 加载订单列表
    initData() {
      const $this = this;
      const params = {
        'start': $this.pager.index * $this.pager.pageSize,
        'length': $this.pager.pageSize,
        'searchValue': $this.searchValue
      };
      this.$http.post('/multilingualOrder/qc/list', params)
        .then((response) => {
          const res = response.data;
          if (res.status === 'success') {
            $this.pager.total = res.data.total;
            $this.data = res.data.data;
          } else {
            $this.showtoasts('danger', res.data);
          }
        }).catch((error) => {
          $this.showtoasts('danger', error.message);
        });
    },
    getLanguageList() {
      const $this = this;
      this.$http.get('/multilingualOrder/lang/list')
        .then((response) => {
          const res = response.data;
          if (res.status === 'success') {
            res.data.forEach(el => {
              $this.languageMap[el.languageId] = el;
            });
          }
          $this.initData();
        }).catch((error) => {
          $this.showtoasts('danger', error);
        });
    },
    // 获取供应商
    getSupplier() {
      this.$http.get('/multilingualUser/supplier/map')
        .then((response) => {
          const res = response.data;
          if (res.status === 'success') {
            this.supplier = res.data;
          }
        });
    },

    // 提示窗
    showtoasts(type, message) {
      this.toasts = [{ type, summary: '提示', detail: message }];
    },
    // 分页
    onPage() {
      this.checkall = false;
      this.initData();
    },
    expired(expected) {
      if (expected) {
        return new Date().getTime() > new Date(expected).getTime();
      }
      return false;
    }
  }
};
</script>

<style scoped>
.orderlist-bottom{
  padding-top:24px;
}
.orderlist-bottom span{
  display:inline-block;
}
.orderlist-checkbox{
  display:inline-block;
  width:16px;
  height:16px;
  border-radius: 50%;
  cursor: pointer;
}
.checkbox-unchecked{
 border:1px solid grey;
 background-color: #fff;
}
.checkbox-checked{
 border:0;
 background-color:#5762ff;
}
.table tr.tr-main{
  border:1px solid lightgrey;
}
.table tr td:first-child span{
  margin-top:16px;
}
.checkbox-checkAll-text{
  width:40px;
}
.orderlist-ul{
  list-style-type: none;
  padding:0;
  margin:0;
}
.orderlist-process{
  cursor: pointer;
  padding:2px 8px;
  text-align:center;
  border-radius:4px;
  background-color: #5762ff;
  color:#fff;
}
.order-detail-title{
  font-size:16px;
  font-weight:600;
}
.dialog-div-main{
  padding:0 0 12px 23%;
  font-size:20px;
}
.dialog-btn{
  display:inline-block;
  padding:4px 8px;
  cursor: pointer;
  border-radius:4px;
  color:#fff;
  background-color: #5762ff;
}
.dialog-btn-middle{
  display:inline-block;
  width:4%;
}
.orderlist-tr-nodata{
  text-align:center;
  color:#666;
  line-height:48px;
}
.orderlist-detail{
  padding-bottom:8px;
  border:1px solid #5762ff;
}
.order-operate-btn-middle{
  padding:2px 0;
}
.table>tbody>tr>td{
 border-top:0;
}
.supplier-mar-right{
  margin-right:4px;
  cursor: pointer;
  font-size:18px;
}
.appraise-top ul{
  padding:0;
  list-style-type: none;
  margin:0;
}
.appraise-top{
  padding-left:24px;
}
.supplier-lang-table{
  width:100%;
}
.supplier-lang-table tr th,td{
  padding:4px 0 4px 12px;
}
.supplier-lang-table-bottom{
  border-top:1px solid rgb(167, 164, 164);
}
.dialog-download-div{
  color:#5762ff;
  text-align: center;
}
.icon{
  cursor: pointer;
}
.qc-top{
  list-style: none;
  padding:0;

}
.qc-top li{
  float:left;

}
.qc-top li.li-left-small{
  margin-left:12px;
}
.qc-top li.li-left-large{
  margin-left:10%;
}
.qc-top li.li-right-search{
  margin-left:26%;
}
.li-btn{
  outline: none;
  border:0;
  line-height:32px;
  width:96px;
  border-radius:8px;
  color:#fff;
  background:#5762ff;
}
.qc-item-info{
  color:#5762ff;
  cursor: pointer;
}
.red{
  color:red;
}
.grey{
  color:#999;
}
</style>
